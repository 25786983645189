import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import Middleware from "@/middleware";

const Dashboard = () =>
    import("@/modules/dashboard/views/Dashboard.vue");
const DashboardPowerBI = () =>
    import("@/modules/dashboard/views/DashboardPowerBI.vue");


export const dashboardPage = {

    path: "/",
    name: "Dashboard",
    component: DashboardLayout,
    children: [

        {
            path: "/dashboard",
            name: "Default",
            component: Dashboard,
            meta: {
                groupName: "Dashboards",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "",
            },
        },
        {
            path: "/dashboard-power-bi",
            name: "PowerBIDashboard",
            component: DashboardPowerBI,
            meta: {
                groupName: "Dashboards",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                // permissions: "dashboard-power-bi-1",
            },
        },


    ]

}